import React from 'react'
import { Breadcrumb, Carousel, Image } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Layout from '../shared/layout/layout'

const {title, images, text} = {
    title: 'Acerca de nosotros',
    images: [
        { title: 'Acerca de Nosotros', url: 'acerca2.jpg' },
    ],
    text: `<p>
    Somos una empresa familiar que vende por menor artículos esotéricos traídos de la India, China, Perú (Cuencos Tibetanos,Lamparas de Sal, Inciensos, Sahumerios, Móviles de viento, fuentes de agua, artículos de Feng Shui etc..) 
    Artículos de decoración Místicos</p>
    <p>Desde nuestro lugar aparte de ser un local comercial buscamos el vínculo fraterno entre seres humanos y si podemos ayudar con alguna palabra, un mensaje de ángeles o simplemente escuchar, es tarea cumplida.</p>`
}

const AboutUs = () => {
    return (
        <Layout>
            <Breadcrumb>
                <Breadcrumb.Item><Link to='/'>Inicio</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>{title}</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className='title'>{title}</h1>
            <Carousel className='border p-1' >
                <Carousel.Item>
                    {
                        images.length>0 && images.map((i) => 
                            (<Image src={i.url} className='d-block w-100' alt={i.title} />)
                        )
                    }
                </Carousel.Item>
            </Carousel>
            <div className='mt-3' dangerouslySetInnerHTML={{__html:text}}></div>
        </Layout>
    )
}
export default AboutUs