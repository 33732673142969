import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, CardDeck, Carousel, Col, Image, Row, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../../app.context'
import Layout from '../shared/layout/layout'
import LazyImage from '../shared/lazyimage'
import AddToCart from './addto-cart'


const formatPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
})
const Product = (param) => {
    console.log(param.match.params)
    let pid = param.match.params.id
    const [config] = useContext(AppContext)
    const [product, setProduct] = useState({})
    const [relatedProducts, setRelatedProducts] = useState([])
    const [loading, setLoading] = useState(true)
    const [loadingImg, setLoadingImg] = useState(true)
    const [images, setImages] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [activeProd, setActiveProd] = useState({})
    useEffect(() => {
        fetch(`${config.api.url}/products/${pid}`)
            .then((response) => response.json())
            .then(data => {
                setProduct(data)
                setActiveProd(data)
            })
    }, [pid])
    useEffect(() => {
        setLoading(true)
        fetch(`${config.api.url}/products/${pid}/related/`)
            .then((response) => response.json())
            .then(data => {
                setLoading(false)
                setRelatedProducts(data)
            })
    }, [pid])
    useEffect(() => {
        setLoadingImg(true)
        fetch(`${config.api.url}/images/${pid}`)
            .then((response) => response.json())
            .then(data => {
                setLoadingImg(false)
                setImages(data)
            })
    }, [pid])

    const zoomHandler = (e) => {
        const { target, offsetX, offsetY, touches } = e.nativeEvent

        let offsetXX = offsetX ?? touches[0].pageX
        let offsetYY = offsetY ?? touches[0].pageY

        let x = offsetXX / target.offsetWidth * 100
        let y = offsetYY / target.offsetHeight * 100
        e.currentTarget.style.backgroundPosition = x + '% ' + y + '%';
    }
    const handleAddToCart = (prod, e) => {
        e.preventDefault()
        setModalShow(true)
        setActiveProd(prod)
    }
    return (

        <Layout>
            <AddToCart product={activeProd} show={modalShow} onHide={() => setModalShow(false)}/>
            <Breadcrumb>
                <Breadcrumb.Item><Link to='/'>Inicio</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={`/productos/`}>Productos</Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to={`/productos/${product?.category?.id}/`}>{product?.category?.name}</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>{product?.name}</Breadcrumb.Item>
            </Breadcrumb>


            <Row>
                <Col md={6}>
                    {
                        loadingImg && <div className='text-center'><Spinner animation="grow" /></div>
                    }
                    <Carousel className='border p-1' >
                        {
                            !loadingImg && images.length > 0 && images.map((i) => {
                                return (
                                    <Carousel.Item key={'ing'+i}>
                                        <figure className='zoom m-0' style={{ backgroundImage: `url(${i?.image})` }} onMouseMove={zoomHandler}>
                                            <Image src={i?.image} className='d-block w-100' alt={product?.name} />
                                        </figure>
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                </Col>
                <Col>
                    <Link to={`/productos/${product?.category?.id}/`}>{product?.category?.name}</Link>
                    <h1 className='title'>{product?.name}</h1>
                    <h2 className=''>
                        {product?.price!=null ? (product?.price > 0 ? formatPeso.format(product?.price) : 'GRATIS') : ''} 
                        <small style={{ color: '#bbb', fontSize: '1.2rem' }}>{product?.oldPrice!=null ? (product?.oldPrice > 0 ? formatPeso.format(product?.oldPrice) : 'GRATIS'): ''}</small>
                    </h2>
                    <div className='' dangerouslySetInnerHTML={{ __html: product?.description }}></div>
                    <hr />
                    <Button variant='purple' onClick={handleAddToCart.bind(this, product)} disabled={product?.stock <= 0}>Agregar al carro</Button>
                </Col>
            </Row>
            <Row className='mt-4 d-none d-md-block'>
                <Col>
                    <h6>Productos relacionados</h6>
                    <hr />
                    {
                        loading && <div className='text-center'><Spinner animation="grow" /></div>
                    }
                    {
                        !loading && relatedProducts.length === 0 && <div>No hay productos relacionados</div>
                    }
                    <Carousel>
                        {
                            !loading && relatedProducts.length > 0 && relatedProducts.map((prod) => {
                                return (
                                    <Carousel.Item style={{ height: '480px' }}>
                                        <CardDeck >

                                            {
                                                prod.length > 0 && prod.map((p) => {
                                                    return (
                                                        <Card className='p-3 border-0 shadow text-center d-inline-block mt-3 card-custom'>
                                                            <Link to={`/productos/${product.category?.id}/${p?.id}`}>
                                                                <LazyImage
                                                                    key={p?.id}
                                                                    src={`${config.api.url}/images/${p?.id}/First`}
                                                                    alt={`Random image`}
                                                                />
                                                                {/* <Card.Img variant="top" src="https://via.placeholder.com/200x170" /> */}
                                                            </Link>
                                                            <Card.Body>
                                                                <Card.Text>
                                                                    <Link to={`/productos/${p.category?.id}`}>{p.category?.name}</Link>
                                                                    <Link to={`/productos/${product.category?.id}/${p?.id}`}>
                                                                        <span style={{ fontSize: '1rem', fontWeight: '600' }} className='d-block'>{p.name}</span>
                                                                    </Link>
                                                                </Card.Text>
                                                                <Card.Title className='font-weight-bold'>{p.price > 0 ? formatPeso.format(p.price) : 'GRATIS'}</Card.Title>
                                                                <Button variant='purple' size='sm' onClick={handleAddToCart.bind(this, p)} disabled={product?.stock <= 0}>Agregar al carro</Button>
                                                            </Card.Body>
                                                        </Card>
                                                    )
                                                })
                                            }
                                        </CardDeck>
                                    </Carousel.Item>
                                )
                            })
                        }
                    </Carousel>
                </Col>
            </Row>

        </Layout>
    )
}
export default Product