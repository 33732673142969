import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Layout from './store/shared/layout/layout'

const NoMatch = () => {
    return (
        <Layout>
            <Breadcrumb>
                <Breadcrumb.Item><Link to='/'>Inicio</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>La página no existe</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className='title'>La página que busca no existe</h1>
            <p>Para volver al inicio pulse <Link to='/' className='font-weight-bold'>aquí.</Link></p>
        </Layout>
    )
}

export default NoMatch