import React, { useContext, useEffect } from 'react'
import Layout from '../shared/layout/layout'
import qs from 'query-string'
import { Link, useHistory } from 'react-router-dom'
import { Breadcrumb } from 'react-bootstrap'
import Moment from 'react-moment'
import { AppContext } from '../../app.context'


const WebpayFinish = (param) => {
    const [config, setConfig] = useContext(AppContext)
    const history = useHistory()
    
    console.log(param.location.search)
    let p = qs.parse(param.location.search)
    if (p.par) {
        localStorage.setItem('webpay-result', p.par)
        history.replace('/compra-exitosa-webpay')
    }

    useEffect(() => {
        let data = localStorage.getItem('webpay-result') || null
        let result = data != null ? JSON.parse(atob(data)) : {}
        if(result.status!='REJECTED'){
            setConfig({...config, cart:[]})
        }
    }, [])

    let data = localStorage.getItem('webpay-result') || null
    let result = data != null ? JSON.parse(atob(data)) : {}

    console.log(result)
    let qcart = localStorage.getItem('cart') || null
    let cart = qcart != null ? JSON.parse(qcart) : []

    let qdespacho = localStorage.getItem('despacho') || null
    let despacho = qdespacho != null ? JSON.parse(qdespacho) : {}

    setTimeout(() => {
        if(result.status!='REJECTED'){
            localStorage.removeItem('cart')
            localStorage.removeItem('despacho')
        }
        localStorage.removeItem('webpay-result')
    }, 5000)
    return (
        <Layout>
            <Breadcrumb>
                <Breadcrumb.Item as='li'><Link to='/'>Inicio</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Compra exitosa</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='title'>
                {result.status == 'AUTHORIZED' && ('Transacción exitosa')}
                {result.status == 'REJECTED' && ('Transacción rechazada')}
                {result.status != 'AUTHORIZED' && result.status != 'REJECTED' && ('Transacción abortada por el usuario')}
            </h1>
            <p>
                {
                    result.status == 'AUTHORIZED' && ('¡Muchas Gracias! El pago por su pedido ha sido realizado.')
                }
            </p>
            <hr />
            {
                result.status == 'REJECTED' && 
                <div>
                    <p>Las posibles causas de este rechazo son:</p>
                    <ul>
                        <li>Error en el ingreso de los datos de su tarjeta de Crédito o Débito (fecha y/o código de seguridad).</li>
                        <li>Su tarjeta de Crédito o Débito no cuenta con saldo suficiente.</li>
                        <li>Tarjeta aún no habilitada en el sistema financiero.</li>
                    </ul>
                </div>
            }
            {
                result.status == 'AUTHORIZED' &&
                (
                    <div>
                        <h2 className='subtitle'>Información del pago</h2>
                        <table className='table table-borderless w-50'>
                            <tbody>
                                <tr>
                                    <th>Número de pedido</th><td>{result.transaction.buyOrder}</td>
                                </tr>
                                <tr>
                                    <th>Total de la compra (CLP)</th><td>${result.transaction.detailOutput[0].amount}</td>
                                </tr>
                                <tr>
                                    <th>Código de autorización</th><td>{result.transaction.detailOutput[0].authorizationCode}</td>
                                </tr>
                                <tr>
                                    <th>Fecha de la transacción</th><td><Moment format='DD/MM/yyyy HH:mm:ss'>{result.transaction.transactionDate}</Moment></td>
                                </tr>
                                <tr>
                                    <th>Tipo de pago</th><td>
                                        {result.transaction.detailOutput[0].paymentTypeCode == 'VC' && (`${result.transaction.detailOutput[0].paymentTypeCode} - Venta en Cuotas`)}
                                        {result.transaction.detailOutput[0].paymentTypeCode == 'VD' && (`${result.transaction.detailOutput[0].paymentTypeCode} - Venta Débito`)}
                                        {result.transaction.detailOutput[0].paymentTypeCode == 'VN' && (`${result.transaction.detailOutput[0].paymentTypeCode} - Venta Normal`)}
                                        {result.transaction.detailOutput[0].paymentTypeCode == 'VP' && (`${result.transaction.detailOutput[0].paymentTypeCode} - Venta Prepago`)}
                                        {result.transaction.detailOutput[0].paymentTypeCode == 'SI' && (`${result.transaction.detailOutput[0].paymentTypeCode} - 3 cuotas sin interés`)}
                                        {result.transaction.detailOutput[0].paymentTypeCode == 'S2' && (`${result.transaction.detailOutput[0].paymentTypeCode} - 2 cuotas sin interés`)}
                                        {result.transaction.detailOutput[0].paymentTypeCode == 'NC' && (`${result.transaction.detailOutput[0].paymentTypeCode} - ${result.transaction.detailOutput[0].sharesNumber} cuotas sin interés`)}
                                    </td>
                                </tr>
                                <tr>
                                    <th>Nº Tarjeta</th><td>XXXX-XXXX-XXXX-{result.transaction.cardDetail.cardNumber}</td>
                                </tr>
                                <tr className={`
                                    ${result.transaction.detailOutput[0].paymentTypeCode == 'VC' ||
                                        result.transaction.detailOutput[0].paymentTypeCode == 'SI' ||
                                        result.transaction.detailOutput[0].paymentTypeCode == 'S2' ||
                                        result.transaction.detailOutput[0].paymentTypeCode == 'NC'
                                        ? 'd-md-table-row' : 'd-none'}`}>
                                    <th>Cuotas</th><td>{result.transaction.detailOutput[0].sharesNumber} cuota(s) de ${result.transaction.detailOutput[0].sharesAmount}</td>
                                </tr>
                            </tbody>
                        </table>
                        <h2 className='subtitle'>Información del pedido</h2>
                        <table className='table table-borderless w-50'>
                            <tbody>
                                {
                                    cart && cart.length > 0 && cart.map((p) => {
                                        return (
                                            <tr>
                                                <td>{p.quantity}</td>
                                                <td>{p.product.Name}</td>
                                                <td>${p.product.Price * p.quantity}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        <h2 className='subtitle'>Información de despacho</h2>
                        <table className='table table-borderless w-50'>
                            <tbody>
                                <tr>
                                    <th>Nombre</th><td>{despacho.name}</td>
                                </tr>
                                <tr>
                                    <th>Teléfono</th><td>{despacho.phone}</td>
                                </tr>
                                <tr>
                                    <th>Email</th><td>{despacho.email}</td>
                                </tr>
                                <tr>
                                    <th>Dirección</th><td>{despacho.address}</td>
                                </tr>
                                <tr>
                                    <th>Referencias</th><td>{despacho.refs}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            <strong>Retiro en tienda </strong> Galeria Alessandri Local 32 (Anibal Pinto 450, Concepción) <br/><br/>
                            <strong>Envíos a domicilio </strong> Coordinar al celular 977916475, si es dentro de Concepción, hacemos el envío por UBER (por pagar). Para otras regiones, enviamos por STARKEN, por pagar, a la dirección indicada <strong>{despacho.address}, {despacho.city}</strong>.
                        </p>
                    </div>
                )
            }
        </Layout>
    )
}
export default WebpayFinish