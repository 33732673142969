import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../../app.context'
import Layout from '../shared/layout/layout'

const RetiroFinish = () => {
    const [config, setConfig] = useContext(AppContext)
    const [despacho, setDespacho] = useState({})
    const [retiro, setRetiro] = useState({})

    
    
    
    useEffect(() => {
        let despachoD = localStorage.getItem("despacho") || null
        despachoD = despachoD!=null ? JSON.parse(despachoD) : {} 
        setDespacho(despachoD)

        let retiroD = localStorage.getItem('retir_res') || null
        retiroD = retiroD!=null ? JSON.parse(retiroD) : {}
        setRetiro(retiroD)

        setConfig({...config, cart:[]})
    }, [])


    setTimeout(() => {
        localStorage.removeItem('cart')
        localStorage.removeItem('despacho')
        localStorage.removeItem('retir_res')
    }, 5000)
    return (
        <Layout>
            <Breadcrumb>
                <Breadcrumb.Item as='li'><Link to='/'>Inicio</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Pedido procesado</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='title'>Pedido procesado</h1>
            <p>¡Muchas gracias! Su pedido ha sido procesado, con la modalidad de Retiro en Tienda.</p>
            <hr/>
            <p>
                Debe realizar una transferencia bancaria por el monto <strong>(${retiro.paymentTotal})</strong> a la cuenta indicada.<br/><br/>

                <strong>BancoEstado Cta Rut 6930753</strong><br/>
                Carmen Merino<br/>
                6.930.753-1
            </p>
            <p>
                Enviar comprobante de transferencia al correo electrónico <strong>{config.catalog.mailPrincipal} </strong> 
                 indicando en el asunto <strong>Pago Bazar Angeles (ref.{retiro.number})</strong> o bien via Whatsapp al número {config.contacts[0].phone} indicando la ref. {retiro.number} y la fecha de compra.
            </p>
            <p>
                <strong>Puede retirar en nuestro local ubicado en la Galería Alessandri local 32 (Aníbal Pinto 450) Concepción.</strong>
            </p>
            
            <p>
                ¡Gracias por preferirnos!
            </p>
        </Layout>
    )
}
export default RetiroFinish