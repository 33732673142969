import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSearch, faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import React, { useContext, useState, useEffect } from 'react'
import {  Row, Col, Image, Dropdown, FormControl, Badge } from 'react-bootstrap'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { AppContext } from '../../../app.context';
import queryString from 'query-string'

// const categories = [
//     { id:1, name:'asd', url:'/productos?cat=123'},
//     { id:2, name:'asd', url:'/productos?cat=123'}
// ]
const Header = ({s}) => {
    const [config] = useContext(AppContext)
    const [categories, setCategories] = useState([])
    const [search, setSearch] = useState(s)
    const history = useHistory()
    
    useEffect(() => {
        fetch(config.api.url+'/categories')
            .then((response) => response.json())
            .then(data => setCategories(data))
    }, [])
    const handleSearch = (e) => {
        e.preventDefault()
        if(e.keyCode === 13){
            if(search && search!=='' && search!=='undefined'){
                history.push(`/productos?s=${search}`)
            }else{
                history.push(`/productos`)
            }
            // console.log(search)
        }
    }
    const handleChange = (e) =>  {
        const {name, value} = e.currentTarget
        setSearch(value)
    }
    return (
        <Row style={{maxHeight:'100px'}} className=''>
            <Col style={{maxHeight:'inherit'}} className='pt-3 pb-3' md={1} xs={2}>
                <Link to='/'>
                    <Image src='/logo_v1.png' className='h-100 d-block' />
                </Link>
            </Col>
            <Col style={{lineHeight:'90px'}} className='ml-4' md={2} xs={2}>
                <Dropdown >
                    <Dropdown.Toggle variant="outline-purple btn-circle" id="categorias">
                        <FontAwesomeIcon icon={faBars} />
                    </Dropdown.Toggle>
                    <span className="ml-2 d-md-inline-block d-none" style={{width:'20px', lineHeight:'15px', position:'relative', top:'8px'}}>Menú Categorías</span>
                    <Dropdown.Menu >
                        {
                            categories && categories.map((obj) => {
                                return (
                                    <Dropdown.Item key={obj.id} style={{lineHeight:'24px'}}>
                                        <Link to={`/productos/${obj.id}`}>{obj.name}</Link>
                                    </Dropdown.Item>
                                )
                            })
                        }
                    </Dropdown.Menu>
                </Dropdown>
            </Col>
            <Col>
                <div className='position-absolute mt-4 w-100 pr-md-3'>
                    <FontAwesomeIcon icon={faSearch} className='position-absolute' style={{top:'13px', left:'15px'}} />
                    <FormControl 
                    style={{borderRadius:'30px', textIndent:'20px'}} 
                    onKeyUp={handleSearch} 
                    value={search} 
                    onChange={handleChange} 
                    name='searchBox'
                    />

                </div>
            </Col>
            <Col md={1} xs={2} className='text-right'>
                <Link to='/carro-de-compra' style={{lineHeight:'90px'}} className="position-relative mr-2" >
                    <FontAwesomeIcon icon={faShoppingCart} style={{fontSize:'1.2rem'}} />
                    <Badge variant='purple' style={{fontSize:'.68rem', top: '-3px'}} className='position-absolute' >{config.cart.length > 0 ? config.cart.map(x => x.quantity).reduce((a, b) => a+b) : 0}</Badge>
                </Link>
            </Col>
        </Row>
    )
}

export default Header
