import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../../app.context'
import Layout from '../shared/layout/layout'
import ProductCategoryList from './product-category'
import ProductSearch from './product-search'
import queryString from 'query-string'

// const categories = [
//     { id: 1, name: 'Feng Shui', url: 'feng-shui' },
//     { id: 2, name: 'Feng Shui', url: 'feng-shui' },
//     { id: 3, name: 'Feng Shui', url: 'feng-shui' },
//     { id: 4, name: 'Feng Shui', url: 'feng-shui' },
// ]

const Products = (param) => {
    const [activeCat, setActiveCat] = useState({})
    const [nav, setNav] = useState([
        { name:'Productos', url:'/productos'}
    ])

    const catId = param.match.params.category;
    const searchParam = queryString.parse(param.location.search).s;
    const [config] = useContext(AppContext)
    const [categories, setCategories] = useState([])
    useEffect(() => {
        fetch(config.api.url+'/categories')
            .then((response) => response.json())
            .then(data => {
                setCategories(data)
                if(catId!=null){
                    let icat = data.find(x => x.id === catId)
                    setActiveCat(icat)
                    // let tnav = { name:icat.Name, url:`/productos/${icat.Id}/`, active:true }
                    setNav([{ name:'Productos', url:'/productos'}, { name:icat.name, url:`/productos/${icat.id}/`, active:true }]);
                }else{
                    setNav([
                        { name:'Productos', url:'/productos'}
                    ])
                }
            })
    }, [catId])
    return (
        
        <Layout search={searchParam}>
            <Breadcrumb>
                <Breadcrumb.Item><Link to='/'>Inicio</Link></Breadcrumb.Item>
                {
                    nav.length > 0 && nav.map((n) => (
                        <Breadcrumb.Item active={n.active}><Link to={n.url}>{n.name}</Link></Breadcrumb.Item>
                    ))
                }
            </Breadcrumb>
            {
                catId && <ProductCategoryList category={activeCat} />
            }
            {
                !catId && <ProductSearch search={searchParam}/>
            }
            

        </Layout>
    )
}

export default Products