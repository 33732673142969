import React, { useContext, useEffect } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../../app.context'
import Layout from '../shared/layout/layout'

const TransferenciaFinish = () => {
    const [config, setConfig] = useContext(AppContext)
    let despacho = localStorage.getItem("despacho") || null
    despacho = despacho!=null ? JSON.parse(despacho) : {} 

    let transaction = localStorage.getItem('tran_res') || null
    transaction = transaction!=null ? JSON.parse(transaction) : {}

    useEffect(() => {
        setConfig({...config, cart:[]})
    }, [])


    setTimeout(() => {
        localStorage.removeItem('cart')
        localStorage.removeItem('despacho')
        localStorage.removeItem('tran_res')
    }, 5000)
    return (
        <Layout>
            <Breadcrumb>
                <Breadcrumb.Item as='li'><Link to='/'>Inicio</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Compra exitosa</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='title'>Compra exitosa</h1>
            <p>¡Muchas gracias! Su compra ha sido realizada, solo queda pendiente realizar el pago.</p>
            <hr/>
            <p>
                Para completar el proceso debe realizar una transferencia por el monto total <strong>(${transaction.paymentTotal})</strong> a la cuenta indicada.<br/><br/>

                <strong>BancoEstado Cta Rut 6930753</strong><br/>
                Carmen Merino<br/>
                6.930.753-1
            </p>
            <p>
                Enviar comprobante de transferencia al correo electrónico <strong>{config.catalog.mailPrincipal} </strong> 
                 indicando en el asunto <strong>Pago Bazar Angeles (ref.{transaction.number})</strong>
            </p>
            <p>
                <strong>Retiro en tienda </strong> Galeria Alessandri Local 32 (Anibal Pinto 450, Concepción) <br/><br/>
                <strong>Envíos a domicilio </strong> Coordinar al celular 977916475, si es dentro de Concepción, hacemos el envío por UBER (por pagar). Para otras regiones, enviamos por STARKEN, por pagar, a la dirección indicada <strong>{despacho.address}, {despacho.city}</strong>.
            </p>
            
            <p>
                ¡Gracias por preferirnos!
            </p>
        </Layout>
    )
}
export default TransferenciaFinish