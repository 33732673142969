import React, { useEffect, useState } from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";
import LazyLoad from "react-lazyload";

const ImageWrapper = styled.div`
  // position: relative;
  // width: 100%;
  // height: 50vw;
`;

const loadingAnimation = keyframes`
  0% {
    background-color: #fff;
  }
  50% {
    background-color: #ccc;
  }
  100% {
    background-color: #fff;
  }
`;

const Placeholder = styled.div`
  ${props => props.align == 'left' ? 'width:200px':'width:100%'};
  ${props => props.align == 'left' ? 'height:100%':'height:200px'};
  animation: ${loadingAnimation} 1s infinite;
`;

const StyledImage = styled.img`
  ${props => props.align == 'left' ? 'max-width:200px;' : ''}
  // position: absolute;
  // left: 0;
  // width: 100%;
  // height: 100%;
  object-fit: cover;
`;

const LazyImage = ({ src, alt, align }) => {
  const [picture, setPicture] = useState('')
  const [loading, setLoading] = useState(true)
  const refPlaceholder = React.useRef();

  const removePlaceholder = () => {
    refPlaceholder.current.remove();
  };
  useEffect(() => {
    setLoading(true)
      fetch(src)
          .then(async (response) => {
            let r = await response.text()
            // console.log(response.text())
            setLoading(false)
            return r
          })
          .then(data => setPicture(data))
  }, [src])
  return (
    <ImageWrapper className="" style={{'height':'200px', 'overflow':'hidden'}}>
      <Placeholder ref={refPlaceholder} align={align}/>
      {/* <LazyLoad> */}
      {
        !loading && picture &&
          <img 
            src={picture.replace('"', '').replace('"', '')}
            style={{'height':'100%'}}
            onLoad={removePlaceholder}
          />
      }
        {/*
          !loading && picture && <StyledImage
                                            className='h-100'
                                            align={align}
                                            onLoad={removePlaceholder}
                                            onError={removePlaceholder}
                                            src={picture.replace('"', '').replace('"', '')}
                                            alt={alt}
                                          />
                                          */}
        
      {/* </LazyLoad> */}
    </ImageWrapper>
  );
};

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired
};

export default LazyImage
