import { faBuilding } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb, Button, Card, Col, Form, Modal, Row } from 'react-bootstrap'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { AppContext } from '../../app.context'
import Layout from '../shared/layout/layout'
import LazyImage from '../shared/lazyimage'
import toastr from 'toastr'


const formatPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
})
const Cart = () => {
    const [config, setConfig] = useContext(AppContext)
    const history = useHistory()
    const [modalShow, setModalShow] = useState(false)
    const [cart, setCart] = useState(config.cart)
    const [webpayActive, setWebpayActive] = useState(true)
    const [payment, setPayment] = useState('transferencia')
    let desp = localStorage.getItem('despacho') || '{}'
    const [despacho, setDespacho] = useState(JSON.parse(desp))
    const [resume, setResume] = useState({
        totalProductos: config.cart.length > 0 ? config.cart.map(x => x.quantity).reduce((a, b) => a + b) : 0,
        total: config.cart.length > 0 ? config.cart.map(x => { return parseInt(parseInt(x.quantity) * parseInt(x.product.price)) }).reduce((a, b) => a + b) : 0
    })

    useEffect(() => {
        fetch(config.api.url + '/catalog')
            .then((response) => response.json())
            .then(data => {
                console.log(data)
                setWebpayActive(data.webpay_active === '1')
            })
    }, [])
    const handleRemove = (c, e) => {
        console.log(c, e)
        let temp = [...cart.filter(x => x.product.id !== c.product.id)]
        setCart(temp)
        localStorage.setItem('cart', JSON.stringify(temp))
        setConfig({ ...config, cart: temp })
        setResume({
            ...resume,
            totalProductos: temp.length > 0 ? temp.map(x => x.quantity).reduce((a, b) => a + b) : 0,
            total: temp.length > 0 ? temp.map(x => { return parseInt(parseInt(x.quantity) * parseInt(x.product.price)) }).reduce((a, b) => a + b) : 0
        })

    }
    const handleQuantityChange = (c, e) => {
        if (parseInt(e.target.value) > 0) {
            c.quantity = parseInt(e.target.value)
            let temp = [...cart.map(x => {
                if (x.product.id === c.product.id) {
                    return c
                }
                return x
            })]
            setCart(temp)
            localStorage.setItem('cart', JSON.stringify(temp))
            setConfig({ ...config, cart: temp })
            setResume({
                ...resume,
                totalProductos: temp.length > 0 ? temp.map(x => x.quantity).reduce((a, b) => a + b) : 0,
                total: temp.length > 0 ? temp.map(x => { return parseInt(parseInt(x.quantity) * parseInt(x.product.price)) }).reduce((a, b) => a + b) : 0
            })
        }
        // config.cart = config.cart.filter(x => x.product.Id !=c.product.Id)
        // config.cart = [...config.cart, c]
        // console.log(config.cart)
    }
    const isEmail = (email) => {
        let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
        return pattern.test(email)
    }
    const handlePayment = async (e) => {
        if (despacho.name) {
            //validations
            let data = {
                despacho: despacho,
                pedido: cart.map(x => {
                    return {
                        quantity: x.quantity,
                        productId: x.product.id,
                        price: x.product.price,
                        name: x.product.name
                    }
                }),
                total: resume.total
            }
            if (payment === 'webpay') {
                let result = await fetch(`${config.api.url}/webpay/createOrder`, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                let response = await result.json()
                if (result.ok) {
                    window.location.href = `${config.api.url}/webpay/init?amount=${response.paymentTotal}&order=${response.number}`
                } else {
                    toastr.error('No es posible realizar el pago. Intente más tarde.', 'Error')
                }
            }
            if (payment === 'transferencia') {
                let result = await fetch(`${config.api.url}/transferencia/createOrder`, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                let response = await result.json()
                if (result.ok) {
                    localStorage.setItem('tran_res', JSON.stringify(response))
                    history.push('/compra-exitosa-transferencia')
                } else {
                    toastr.error('No es posible realizar el pago. Intente más tarde.', 'Error')
                }
            }
            if (payment === 'retiro') {
                let result = await fetch(`${config.api.url}/retiro/createOrder`, {
                    method: 'POST',
                    body: JSON.stringify(data),
                    headers: {
                        'Content-Type': 'application/json'
                    }
                })
                let response = await result.json()
                if (result.ok) {
                    localStorage.setItem('retir_res', JSON.stringify(response))
                    history.push('/compra-exitosa-retiro')
                } else {
                    toastr.error('No es posible registrar su pedido en este momento. Intente más tarde.', 'Error')
                }

            }
        } else {
            toastr.error(`Para procesar su compra debe indicar la Informacion de ${payment == 'retiro' ? 'Contacto': 'Despacho'}`, 'Error')
        }

    }
    const handleUpdateDespacho = () => {
        var { name, rut, email, phone, address, city, refs } = despacho
        if (!name || name === '') toastr.error('Debe indicar su nombre completo.', 'Error')
        else if (!rut || rut === '') toastr.error('Debe indicar su rut.', 'Error')
        else if (!email || email === '' || !isEmail(email)) toastr.error('Debe indicar un email válido.', 'Error')
        else if (!phone || phone === '') toastr.error('Debe indicar un número telefónico válido.', 'Error')
        else if ((!address || address === '') && payment!='retiro') toastr.error('Debe indicar la dirección de despacho.', 'Error')
        else if ((!city || city === '') && payment!='retiro') toastr.error('Debe indicar la ciudad o comuna de despacho.', 'Error')
        else {
            localStorage.setItem('despacho', JSON.stringify(despacho))
            setModalShow(false)
        }




    }
    return (
        <Layout>
            <Breadcrumb>
                <Breadcrumb.Item as='li'><Link to='/'>Inicio</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>Carro de compra</Breadcrumb.Item>
            </Breadcrumb>
            <h1 className='title'>Carro de compra</h1>
            <p>Continue con su proceso de compra</p>
            <hr />
            <Row>
                <Col className='col-md-8 col-auto'>
                    {
                        !(cart && cart && cart.length > 0) && <span>No hay productos agregados al carro de compras.</span>
                    }
                    {
                        cart && cart && cart.length > 0 && cart.map((c) => {
                            return (
                                <Row className='mb-2'>
                                    <Col>
                                        <Card className='flex-row flex-wrap p-3 border-0 shadow'>
                                            <Card.Body>
                                                <Row>
                                                    <Col md='auto' className='col-5'>
                                                        <Link to={`/productos/${c.product.category.id}/${c.product.id}`}>
                                                            <LazyImage
                                                                align='left'
                                                                key={c.product.id}
                                                                src={`${config.api.url}/images/${c.product.id}/First`}
                                                                alt={`Random image`}
                                                            />
                                                        </Link>
                                                    </Col>
                                                    <Col>
                                                        <Card.Text>
                                                            <Link to={`/productos/${c.product.category.id}`}>{c.product.category.name}</Link>
                                                            <Link to={`/productos/${c.product.category.id}/${c.product.id}`}>
                                                                <span style={{ fontSize: '1rem', fontWeight: '600' }} className='d-block'>{c.product.name}</span>
                                                            </Link>
                                                        </Card.Text>
                                                        <Card.Title className='font-weight-bold'>{c.product.price > 0 ? formatPeso.format(c.product.price) : 'GRATIS'}</Card.Title>
                                                    </Col>
                                                    <Col md='auto'>
                                                        <Form.Control type='number' min='1' max={c.product.stock} className='mt-4' value={c.quantity} onInput={handleQuantityChange.bind(this, c)} />
                                                        <Link onClick={handleRemove.bind(this, c)}>Eliminar</Link>
                                                    </Col>
                                                </Row>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>

                            )
                        })
                    }
                </Col>
                <Col>
                    <Row>
                        <Col>
                            <Card className='flex-row flex-wrap p-3 border-0 shadow'>
                                <Card.Body>
                                    <Card.Text className='mb-0'>
                                        <h4 className='title'>Resumen de la compra</h4>
                                        <p>{resume?.totalProductos} productos</p>
                                    </Card.Text>
                                    <Card.Title className='mb-0'>Total: {formatPeso.format(resume.total)}</Card.Title>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Card className='flex-row flex-wrap p-3 border-0 shadow'>
                                <Card.Body>
                                    <Card.Text className='mb-0'>
                                        <h4 className='title mb-3'>Información de { payment == 'retiro' ? 'Contacto': 'Despacho'}</h4>
                                        {
                                            despacho?.name &&
                                            <ul className='list-unstyled'>
                                                <li className='mb-2'>{despacho?.name}</li>
                                                <li className='mb-2'>{despacho?.rut}</li>
                                                <li className='mb-2'>{despacho?.email}</li>
                                                <li className='mb-2'>{despacho?.phone}</li>
                                                { despacho != 'retiro' && 
                                                    <>
                                                        <li className='mb-2'>{despacho?.address}</li>
                                                        <li className='mb-2'>{despacho?.refs}</li>
                                                    </>
                                                }
                                            </ul>
                                        }
                                        {
                                            !despacho?.name &&
                                            <p>No hay información disponible.</p>
                                        }
                                        
                                        <Button variant='purple' className='btn-sm btn-block' onClick={() => { setModalShow(true) }}>Ingrese sus datos</Button>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row className='mt-2'>
                        <Col>
                            <Card className='flex-row flex-wrap p-3 border-0 shadow'>
                                <Card.Body>
                                    <Card.Text className='mb-0'>
                                        <h4 className='title'>Medio de Pago</h4>
                                        <ul className='list-inline'>
                                            <li><Form.Check type='radio' name='retiro' checked={payment === 'retiro'} value={'retiro'} label='Retiro en tienda' onChange={(e) => { if (e.currentTarget.checked) { setPayment('retiro') } }} /></li>
                                            <li><Form.Check type='radio' name='pago' checked={payment === 'transferencia'} value='transferencia' label='Transferencia Bancaria' onChange={(e) => { if (e.currentTarget.checked) { setPayment('transferencia') } }} /></li>
                                            <li><Form.Check type='radio' disabled={!webpayActive} name='pago' checked={payment === 'webpay'} value='webpay' label='Webpay' onChange={(e) => { if (e.currentTarget.checked) { setPayment('webpay') } }} /></li>
                                        </ul>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {/* <Button variant='purple' className='btn-block mt-4' onClick={() => { window.location.href=`${config.api.url}/webpay/init`}}>Ir a pagar</Button> */}
                            <Button variant='purple' className='btn-block mt-4' onClick={handlePayment}>Procesar pedido</Button>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal
                show={modalShow}
                onHide={() => { setModalShow(false)}}
                size='md'
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className='h6'>
                        <FontAwesomeIcon icon={faBuilding} className='mr-3' />
                        Información de {payment == 'retiro' ? 'Contacto' : 'Despacho'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Control placeholder='Nombre completo' onChange={(e) => { setDespacho({ ...despacho, name: e.target.value }) }} value={despacho.name} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder='Rut' onChange={(e) => { setDespacho({ ...despacho, rut: e.target.value }) }} value={despacho.rut} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder='Email' onChange={(e) => { setDespacho({ ...despacho, email: e.target.value }) }} value={despacho.email} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control placeholder='Teléfono' onChange={(e) => { setDespacho({ ...despacho, phone: e.target.value }) }} value={despacho.phone} />
                    </Form.Group>
                    {
                        payment!='retiro' &&
                        <>
                            <Form.Group>
                                <Form.Control placeholder='Dirección' onChange={(e) => { setDespacho({ ...despacho, address: e.target.value }) }} value={despacho.address} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control placeholder='Ciudad' onChange={(e) => { setDespacho({ ...despacho, city: e.target.value }) }} value={despacho.city} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control placeholder='Referencias' onChange={(e) => { setDespacho({ ...despacho, refs: e.target.value }) }} value={despacho.refs} />
                            </Form.Group>
                            <p style={{ fontSize: '.8em' }}><strong>Condiciones de despacho: </strong> El envío será realizado por cobrar vía Starken dentro del territorio nacional de Chile.</p>
                        </>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='purple' onClick={handleUpdateDespacho}>Guardar Información</Button>
                </Modal.Footer>
            </Modal>
        </Layout>
    )
}
export default Cart
