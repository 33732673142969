import React, { useContext, useState } from 'react'
import { Button, Col, Container, Form, Row, Spinner } from 'react-bootstrap'
import { useHistory } from 'react-router-dom';
import toastr from 'toastr'
import { AppContext } from "../../app.context.js";

const Login = () => {
    const [form, setForm] = useState({})
    const [config] = useContext(AppContext)
    const [loading, setLoading] = useState(false)
    const history = useHistory()

    const handleSubmit = async (e) => {
        e.preventDefault()
        setLoading(true)
        if (!form.username || form.username === '') {
            toastr.error('Debe ingresar un usuario válido', 'Error')
        } else if (!form.password || form.password === '') {
            toastr.error('Debe ingresar un password válido', 'Error')
        } else {
            let request = await fetch(`${config.api.url}/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Basic YXBpOmFwaQ=='
                },
                body: JSON.stringify(form)
            })
            let response = await request.json()
            if (request.ok) {
                localStorage.setItem('sess_ba', response.access_token)
                history.push('/admin/dashboard')
            } else {
                toastr.error(response, 'Error')
            }
        }
        setLoading(false)
    }
    return (
        <Container fluid className='m-0 p-0'>
            <Row className='m-0'>
                <Col className='p-0' style={{
                    height: '100vh',
                    backgroundImage: 'url(budismo_4.jpg)',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover'
                }}>
                </Col>
                <Col md='auto' className='d-flex shadow'>
                    <div className='align-self-center p-3 text-center' style={{ width: '250px' }}>
                        <h4 className='title mb-5'>Iniciar sesión</h4>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group>
                                <Form.Control placeholder='Usuario' size='sm' onChange={(e) => { setForm({ ...form, username: e.target.value }) }} />
                            </Form.Group>
                            <Form.Group>
                                <Form.Control type='password' placeholder='Password' size='sm' onChange={(e) => { setForm({ ...form, password: e.target.value }) }} />
                            </Form.Group>
                            <Button type='submit' variant='primary' className='btn-block btn-sm'>
                                {
                                    loading && <Spinner as="span" animation='grow' size='sm' role='status' aria-hidden='true' />
                                }
                                {
                                    !loading && <span>Iniciar sesión</span>
                                }
                            </Button>
                        </Form>
                    </div>
                </Col>
            </Row>
        </Container>
    )
}
export default Login
