import React, { createContext, useState } from "react";
import cfg from './config.json'

export const AppContext = createContext([])



export const AppContextProvider = ({children}) => {
    let cart = localStorage.getItem('cart') || '[]'
    let cfgg = {...cfg, cart:JSON.parse(cart)}
    const [config, setConfig] = useState(cfgg)
    return (
        <AppContext.Provider value={[config, setConfig]}>
            {children}
        </AppContext.Provider>
    )
}