
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardDeck, Pagination, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../../app.context'
import LazyImage from '../shared/lazyimage'
import AddToCart from './addto-cart'
const formatPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  })
const ProductSearch = ({search}) => {
    const [config] = useContext(AppContext)
    const [products, setProducts] = useState({})
    const [loading, setLoading] = useState(true)
    const [modalShow, setModalShow] = useState(false)
    const [activeProd, setActiveProd] = useState({})
    const [pagination, setPagination] = useState({
        pageNumber:1, pageSize:12, totalPages : 0
    })
    const handlePagination = (page, e) => {
        if(page > 0 && page <= pagination.totalPages){
            setPagination({...pagination, pageNumber:page})
        }
    }
    const handleAddToCart = (prod, e) => {
        e.preventDefault()
        setActiveProd(prod)
        setModalShow(true)
    }
    useEffect(() => {
        setLoading(true)
        fetch(`${config.api.url}/products?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}&search=${search}`)
            .then((response) => response.json())
            .then(data => {
                let totalpages = Math.floor(data.total / pagination.pageSize) + (data.total % pagination.pageSize > 0 ? 1 : 0) 
                setPagination({...pagination, totalPages:totalpages})
                setLoading(false)
                setProducts(data)
            })
    }, [search, pagination.pageNumber, pagination.pageSize])
    return (
        <div>
            <AddToCart product={activeProd} show={modalShow} onHide={() => setModalShow(false)}/>
            <h1 className='title'>Búsqueda de productos</h1>
            <p>Utilice el buscador, o navegue en el listado de nuestros productos.</p>   
            <hr/>
            {
                loading && <div className='text-center'><Spinner animation="grow"/></div>
            }
            {
                !loading && products.data && products.data.length === 0 && <span>No hay productos registrados asociados a la búsqueda.</span>
            }
            {
                !loading && products.data && products.data.length > 0 && 
                (<Pagination size='sm' className='justify-content-center'>
                    <Pagination.First onClick={handlePagination.bind(this, 1)}/>
                    <Pagination.Prev onClick={handlePagination.bind(this, pagination.pageNumber - 1)}/>
                     {
                        Array(pagination.totalPages).fill(0).map((k,j) =>{
                            return (
                                <Pagination.Item key={j} active={(j+1) === pagination.pageNumber} onClick={handlePagination.bind(this, (j+1))}>
                                    {j+1}
                                </Pagination.Item>
                            )
                        })
                     }
                    <Pagination.Next onClick={handlePagination.bind(this, pagination.pageNumber + 1)}/>
                    <Pagination.Last onClick={handlePagination.bind(this, pagination.totalPages)}/>
                </Pagination>)
            }
            <CardDeck>
                {
                    !loading && products.data && products.data.length > 0 && products.data.map((prod) => {
                        return (
                            <Card key={prod.id} className='p-3 border-0 shadow text-center mt-3 card-custom' >
                                <Link to={`/productos/${prod.category.id}/${prod.id}`}>
                                    <LazyImage
                                        key={prod.id}
                                        src={`${config.api.url}/images/${prod.id}/First`}
                                        alt={`Random image`}
                                    />
                                </Link>
                                <Card.Body>
                                    <Card.Text>
                                        <Link to={`/productos/${prod.category.id}`}>{prod.category.name}</Link>
                                        <Link to={`/productos/${prod.category.id}/${prod.id}`}>
                                            <span style={{fontSize:'1rem', fontWeight:'600'}} className='d-block'>{prod.name}</span>
                                        </Link>
                                    </Card.Text>
                                    <Card.Title className='font-weight-bold'>{prod.price > 0 ? formatPeso.format(prod.price) : 'GRATIS'}</Card.Title>
                                    <Button variant='purple' size='sm' onClick={handleAddToCart.bind(this, prod)} disabled={prod.stock <= 0}>Agregar al carro</Button>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </CardDeck>
            {
                !loading && products.data && products.data.length > 0 && 
                (<Pagination size='sm' className='justify-content-center mt-5'>
                    <Pagination.First onClick={handlePagination.bind(this, 1)}/>
                    <Pagination.Prev onClick={handlePagination.bind(this, pagination.pageNumber - 1)}/>
                     {
                        Array(pagination.totalPages).fill(0).map((k,j) =>{
                            return (
                                <Pagination.Item key={j} active={(j+1) === pagination.pageNumber} onClick={handlePagination.bind(this, (j+1))}>
                                    {j+1}
                                </Pagination.Item>
                            )
                        })
                     }
                    <Pagination.Next onClick={handlePagination.bind(this, pagination.pageNumber + 1)}/>
                    <Pagination.Last onClick={handlePagination.bind(this, pagination.totalPages)}/>
                </Pagination>)
            }
        </div>
    )
}
export default ProductSearch