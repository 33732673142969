import React, { useContext, useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../../../app.context'

const Top = (param) => {
    const [config, setConfig] = useContext(AppContext)
    const [ catalog, setCatalog ] = useState([])
    // console.log(param)
    useEffect(() => {
        fetch(config.api.url+'/catalog')
            .then((response) => response.json())
            .then(data => {
                setConfig({...config, catalog: data})
                setCatalog(data)

            })
    }, [])

    
    return (
        <Container fluid className='m-0 p-0' style={{backgroundColor:'#f2f2f2'}}> 
            <Container>
                <Row>
                    <Col>
                        <ul className='list-inline mb-0'>
                            <li className='d-inline-block' key={catalog.telefono1}><a href={`tel:${catalog.telefono1}`} className='pt-3 pb-3 d-block'>{catalog.telefono1}</a></li>
                            <li className='d-inline-block ml-2' key={catalog.telefono2}><a href={`tel:${catalog.telefono2}`} className='pt-3 pb-3 d-block'>{catalog.telefono2}</a></li>
                            <li className='d-md-inline-block ml-2 d-none'><a href={`mailto:${catalog.mailPrincipal}`} className='pt-3 pb-3 d-block'>{catalog.mailPrincipal}</a></li>
                        </ul>
                    </Col>
                    <Col md='3' className='d-none d-sm-block' >
                        <ul className='list-inline mb-0'>
                            <li className='d-inline-block'><Link to='/acerca-de-nosotros' className='pt-3 pb-3 d-block'>Nosotros</Link></li>
                            <span className='pr-1 pl-1'>|</span>
                            <li className='d-inline-block'><Link to='/contactanos' className='pt-3 pb-3 d-block'>Contáctanos</Link></li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}

export default Top
