import React from 'react'
import { Container } from 'react-bootstrap'
import Footer from './footer'
import Header from './header'
import Top from './top'
import '../../styles.scss'
const Layout = ({children, search}) => {
    return (
        <div className='mainWrapper'>
            <Top target={children}/>
            <Container style={{minHeight:'95vh'}}>
                <Header s={search} />
                {children}
            </Container>
            <Footer/>
        </div>
    )
}
export default Layout