import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap'
import { AppContext } from '../../app.context'
import toastr from 'toastr'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'


const FormBigGroup = styled.div`
    padding:10px 5px;
    outline:1px solid #ddd;
    
`

const CategoriesForm = (props) => {
    const [form, setForm] = useState({status:'1'})
    const [loading, setLoading] = useState(false)
    const [config] = useContext(AppContext)
    const history = useHistory()
    const [roles, setRoles] = useState([])
    
    let token = localStorage.getItem('sess_ba') || null

    if(token == null) history.push('/admin/login')

    useEffect(() => {
        if(props.active!=null){
            setForm({...form, 
                _id:props.active._id,
                id:props.active.id,
                name:props.active.name,
                description:props.active.description,
                status:props.active.status?.toString(),
            })
        }
    }, [props.active])
    const handleSubmit = async (e) => {
        e.preventDefault()
        if(form?.Name === ''){ toastr.error('Debe indicar un nombre', 'Error')}
        else{
            let data = {
                id: form.id,
                name:form.name, 
                description:form.description, 
                status:parseInt(form.status),
            }
            let request;
            if(form._id){
                data._id = form._id;
                request = await fetch(`${config.api.url}/categories/${form._id}`,{
                    method: 'PUT',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
                    body: JSON.stringify(data)
                })
            }else{
                request = await fetch(`${config.api.url}/categories`,{
                    method: 'POST',
                    headers: {'Content-Type': 'application/json', 'Authorization': 'Bearer '+token},
                    body: JSON.stringify(data)
                })
            }
            if(request.ok){
                setForm({})
                toastr.success(`La operación se realizó exitosamente.`, 'Éxito')
                props.onHide()
                props.onSave()
            }else{
                toastr.error('No es posible realizar la operación', 'Error')
            }
    
            setLoading(false)
        }
    }
    
    return (
        <Modal
            {...props}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='h6'>
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                
                <Form>
                    <Form.Group as={Row}>
                        <Form.Label column sm='3'>Nombre</Form.Label>
                        <Col sm='9'>
                            <Form.Control onChange={(e) => { setForm({ ...form, name: e.target.value }) }} value={form?.name || ''} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm='3'>Descripción</Form.Label>
                        <Col sm='9'>
                            <Form.Control as='textarea' onChange={(e) => { setForm({ ...form, description: e.target.value }) }} value={form?.description || ''} />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm='3'>Estado</Form.Label>
                        <Col sm='9'>
                            <Form.Control as='select' onChange={(e) => { setForm({ ...form, status: e.target.value }) }} value={form?.status || ''} >
                                <option>Seleccione...</option>
                                <option value='1'>Activo</option>
                                <option value='2'>Inactivo</option>
                            </Form.Control>
                        </Col>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleSubmit}>
                    {
                        loading && <Spinner as="span" animation='grow' size='sm' role='status' aria-hidden='true' />
                    }
                    {
                        !loading && <span>{form.id ? 'Modificar' : 'Crear'}</span>
                    }
                </Button>
                <Button variant='secondary' onClick={() => { props.onHide() }}>Cancelar</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default CategoriesForm