import React from 'react'
import Layout from '../shared/layout'


const Dashboard = () => {
    return (
       <Layout pageTitle='Dashboard' pageUrl='dashboard'>
           <p>
               Bienvenido a la administración de BazarAngeles.cl
           </p>
       </Layout>
    )
}
export default Dashboard