import { faEdit, faPlusCircle, faTrash, faSearch } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext, useEffect, useState } from 'react'
import { Badge, Breadcrumb, Button, Col, Form, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import DataTable from 'react-data-table-component'
import styled from 'styled-components'
import { AppContext } from '../../app.context'
import toastr from 'toastr'
import Layout from '../shared/layout'
import { Link, useHistory } from 'react-router-dom'

const Wrapper = styled.div`
    width:100%;
`

const Transactions = () => {
    const [config] = useContext(AppContext)
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(true)
    const [pagination, setPagination] = useState({ pageNumber: 1, pageSize: 10, total: 1, sort: 'name', sortDirection: 'asc', search: '' })
    const [showModal, setShowModal] = useState(false)
    const [active, setActive] = useState({})
    const [updated, setUpdated] = useState(false)
    const [title, setTitle] = useState('Crear Categoría')
    const history = useHistory()

    let token = localStorage.getItem('sess_ba') || null

    if(token == null) history.push('/admin/login')
    const columns = [
        { name: 'Number', selector: 'number', sortable: true },
        { name: 'Fecha', selector: 'createdAt', sortable: true },
        { name: 'Tipo de Pago', selector: 'paymentType', sortable: true },
        { name: 'Monto', selector: 'paymentTotal', sortable: true },
        { name: 'Estado', selector:'status', sortable:true,cell: (r) => {
            return (
                <span>
                    { r.status === 1 && <Badge variant="success">Activo</Badge> }
                    { r.status === 2 && <Badge variant="danger">Inactivo</Badge> }
                </span>
            )
        }},
        {
            name: '',
            button: true,
            cell: (r) => {
                return (
                    <div>
                        <OverlayTrigger
                            placement='left'
                            overlay={(
                                <Tooltip id={`tooltip-right`}>
                                    Ver info
                                </Tooltip>
                            )}
                        >
                            <Button className='btn-sm' variant='info' onClick={handleEdit.bind(this, r)}> <FontAwesomeIcon icon={faSearch} /> </Button>
                        </OverlayTrigger>
                    </div>
                )
            }
        }
    ]
    useEffect(() => {
        setLoading(true)
        fetch(`${config.api.url}/transactions/paginated?pageNumber=${pagination.pageNumber}&pageSize=${pagination.pageSize}&sort=${pagination.sort}&sortDirection=${pagination.sortDirection}&s=${pagination.search}`)
            .then(r => r.json())
            .then(data => {
                setList(data.data)
                setPagination({ ...pagination, total: data.total })
                setLoading(false)
                setUpdated(false)
            })
    }, [pagination.pageNumber, pagination.pageNumber, pagination.sort, pagination.sortDirection, pagination.search, updated])
    const handleSort = (col, sortdirection) => {
        setPagination({ ...pagination, sort: col.selector, sortDirection: sortdirection })
    }
    const handleEdit = (r, e) => {
        setTitle('Modificar categoría')
        setActive(r)
        setShowModal(true)
    }
    const handleAdd = () => {
        setTitle('Crear categoría')
        setActive({})
        setShowModal(true)
    }
    const handleRemove = async (r, e) => {
        if (window.confirm("¿Está seguro de eliminar este elemento?")) {
            let request = await fetch(`${config.api.url}/categories/${r._id}`, { method: 'DELETE', headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer '+token } })
            if (request.ok) {
                toastr.success('El elemento se eliminó correctamente.', 'Éxito')
                setUpdated(true)
            } else {
                let result = await request.json()
                toastr.error('El elemento no se eliminó. <br>[' + (result.Message || result) + ']', 'Error')
            }
        }
    }
    const handlePageChange = (page) => {
        setPagination({...pagination, pageNumber:page})
    }
    const handlePerRowsChange = (pageLength, page) => {
        setPagination({...pagination, pageSize:pageLength})
    }
    const handleSearch = (e) => {
        if(e.target.value!==''){
            if(e.keyCode === 13) {
                setPagination({...pagination, search:e.target.value})
            }
        }else{
            setPagination({...pagination, search:e.target.value})
        }
    }
    return (
        <Layout pageTitle='Transacciones' pageUrl='transactions'>
            <Breadcrumb>
                <Breadcrumb.Item as={Link} to='/'>Dashboard</Breadcrumb.Item>
                <Breadcrumb.Item active>Transacciones</Breadcrumb.Item>
            </Breadcrumb>
            <hr className='mt-0' />
            <Wrapper>
                <Row className='mb-2'>
                    <Col>
                        <Form.Group><Form.Control placeholder='Buscar por nombre' size='sm' onKeyUp={handleSearch}/></Form.Group>
                    </Col>
                    <Col className='col-auto'>
                    </Col>
                </Row>
                <DataTable
                    noHeader={true}
                    columns={columns}
                    data={list}
                    onSort={handleSort}
                    progressPending={loading}
                    progressComponent={<Spinner as="span" animation='grow' size='sm' role='status' aria-hidden='true' className='mt-5 mb-5' />}
                    persistTableHead
                    responsive
                    pagination
                    paginationServer
                    paginationTotalRows={pagination.total}
                    onChangePage={handlePageChange}
                    onChangeRowsPerPage={handlePerRowsChange}
                />
            </Wrapper>
        </Layout>
    )
}
export default Transactions
