import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faUserAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'

const redes = [
    {name:'Facebook', icon:faFacebookF, url:'https://www.facebook.com/bazarangelesconcepcion/'},
    {name:'Instagram', icon:faInstagram, url:'https://www.instagram.com/bazar_angeles_32/'}
]
const Footer = () => {
    return (
        <Container fluid className=' p-0 mt-5' style={{backgroundColor:'#555', color:'#bbb'}}> 
            <Container>
                <Row className='pt-4 pb-4' >
                    <Col>Bazar Ángeles &copy; {new Date().getFullYear()}</Col>
                    <Col className='text-center'>
                        <ul className='list-inline mb-0'>
                            {
                                redes.length > 0 && redes.map((red) => {
                                    return (
                                        <li className='d-inline-block mr-3' key={red.name}>
                                            <Link  to={{pathname:red.url}} target='_blank' title={red.name} className='footerLink'>
                                                <FontAwesomeIcon icon={red.icon}/>
                                            </Link>
                                        </li>
                                    )
                                })
                            }
                            <li className='d-inline-block mr-3'>
                                <Link key='loginl' to='/login' className='footerLink' title='Administración'>
                                    <FontAwesomeIcon icon={faUserAlt}/>
                                </Link>
                            </li>
                        </ul>
                    </Col>
                    <Col className='text-right'>
                        <Link to='/terminos-y-condiciones' className='footerLink'>Condiciones del servicio</Link>
                    </Col>
                </Row>
            </Container>
        </Container>
    )
}
export default Footer