import React, { useContext, useEffect, useState } from 'react'
import { Breadcrumb, Button, Col, Form, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { AppContext } from '../../app.context'
import Layout from '../shared/layout/layout'
import toastr from 'toastr'

const {title, location} = {
    title: 'Contáctanos',
    location: {
        lat:'-73.0516665487513',
        lng:'-36.82699298589772'
    }
}
const isEmail = (email) => {
    let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i)
    return pattern.test(email)
}
const ContactUs = () => {
    const [config] = useContext(AppContext)
    const [ catalog, setCatalog ] = useState([])
    const [formdata, setFormdata] = useState({})
    
    useEffect(() => {
        fetch(config.api.url+'/catalog')
            .then((response) => response.json())
            .then(data => setCatalog(data))
    }, [])
    const handleSubmit = async (e) => {
        e.preventDefault()
        const {name, phone, email, message} = formdata
        if(!name || name==''){
            toastr.error('Debe ingresar su nombre', 'Error')
        }
        else if(!phone || phone == ''){
            toastr.error('Debe ingresar su número de teléfono', 'Error')
        }
        else if(!email || email == '' || !isEmail(email)){
            toastr.error('Debe ingresar un email válido.', 'Error')
        }
        else if(!message || message == ''){
            toastr.error('Debe ingresar un mensaje.','Error')
        }else{
            console.log(formdata)
            let request = await fetch(`${config.api.url}/contact`,{
                method:'POST',
                headers:{
                    'Content-Type':'application/json'
                },
                body:JSON.stringify(formdata)
            })
            if(request.ok){
                setFormdata({...formdata, name:'', phone:'', email:'', message:''})
                toastr.success('Su mensaje se envió exitosamente. Nos contactaremos a la brevedad.', 'Éxito')
            }else{
                toastr.error('No es posible enviar su mensaje en este momento, intente más tarde.')
            }
        }

    }
    return (
        <Layout>
            <Breadcrumb>
                <Breadcrumb.Item><Link to='/'>Inicio</Link></Breadcrumb.Item>
                <Breadcrumb.Item active>{title}</Breadcrumb.Item>
            </Breadcrumb>

            <h1 className='title'>{title}</h1>
            <p>Completa el formulario y te contactaremos lo antes posible.</p>
            <Row>
                <Col>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group>
                            <Form.Control placeholder='Nombre' onChange={(e) => { setFormdata({...formdata, name:e.target.value})}} value={formdata.name} />
                        </Form.Group>
                        <Form.Group>
                            <Row>
                                <Col><Form.Control placeholder='Teléfono' onChange={(e) => { setFormdata({...formdata, phone:e.target.value})}} value={formdata.phone} /></Col>
                                <Col><Form.Control placeholder='Email' onChange={(e) => { setFormdata({...formdata, email:e.target.value})}} value={formdata.email} /></Col>
                            </Row>
                        </Form.Group>
                        <Form.Group>
                            <Form.Control as='textarea' placeholder='Mensaje' rows='10' cols='30' onChange={(e) => { setFormdata({...formdata, message:e.target.value})}} value={formdata.message}/>
                        </Form.Group>
                        <Form.Group>
                            <Button variant='purple' className='btn-block' type='submit'>Enviar</Button>
                        </Form.Group>
                    </Form>
                </Col>
                <Col>
                    <iframe allowfullscreen="" title='map' frameborder="0" height="100%" src={`https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3193.6463728020594!2d${catalog.ubicacion && catalog.ubicacion.split(',')[0]}!3d${catalog.ubicacion && catalog.ubicacion.split(',')[1]}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9669b5d0fff2a82b%3A0x6924561d1bc41988!2zQW5pYmFsIFBpbnRvIDQ1MCwgQ29uY2VwY2nDs24sIELDrW8gQsOtbw!5e0!3m2!1ses-419!2scl!4v1580781774723!5m2!1ses-419!2scl`} style={{border:'1px solid #ccc'}} width="100%"></iframe>
                </Col>
            </Row>
        </Layout>
    )
}
export default ContactUs