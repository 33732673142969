import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, CardDeck,  Carousel, Col,   Row, Spinner } from 'react-bootstrap'
import { Link, Redirect, useHistory } from 'react-router-dom'
import { AppContext } from '../../app.context'
import Layout from '../shared/layout/layout'
import LazyImage from "../shared/lazyimage";
import AddToCart from './addto-cart'

const formatPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
  })
const Home = () => {
    const [config] = useContext(AppContext)
    const [starred, setStarred] = useState([])
    const [loadingStar, setLoadingStar] = useState(true)
    const [loadingMain, setLoadingMain] = useState(true)
    const [products, setProducts] = useState([])
    const [modalShow, setModalShow] = useState(false)
    const [activeProd, setActiveProd] = useState({})
    const history = useHistory()
    useEffect(() => {
        setLoadingStar(true)
        fetch(config.api.url+'/products?starred=1&pageNumber=1&pageSize=2')
            .then((response) => response.json())
            .then(data => {
                setLoadingStar(false)
                setStarred(data)
            })
    }, [])
    useEffect(() => {
        setLoadingMain(true)
        fetch(config.api.url+'/products?starred=0&main=1&pageNumber=1&pageSize=15')
            .then((response) => response.json())
            .then(data => {
                setLoadingMain(false)
                setProducts(data)
            })
    }, [])

    const handleAddToCart = (prod, e) => {
        e.preventDefault()
        setActiveProd(prod)
        setModalShow(true)
    }
    return (
        <Layout>
            <AddToCart product={activeProd} show={modalShow} onHide={() => setModalShow(false)}/>
            <Carousel>
                <Carousel.Item>
                    <div style={{'maxHeight':'300px', 'width':'100%'}}>
                        <img
                            className="d-block h-100"
                            src="bgprod1.jpg"
                            alt="Budas, Jardin zen y mas"
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{'maxHeight':'300px', 'width':'100%'}}>
                        <img
                            className="d-block h-100"
                            src="bgprod2.jpg"
                            alt="Cuencos de cuarzo y tibetanos"
                        />
                    </div>
                </Carousel.Item>
            </Carousel>
            {
                loadingStar && <div className='text-center d-none d-md-block'><Spinner animation="grow"/></div>
            }
            <CardDeck className='mt-3 d-none d-md-flex'>
                {
                    !loadingStar && starred && starred.map((prod) => {
                        return (
                            <Card className='flex-row flex-wrap p-4 shadow' key={prod.id}>
                                <Link className="mr-3" to={`/productos/${prod.category.id}/${prod.id}`}>
                                    <LazyImage
                                        align='left'
                                        key={prod.id}
                                        src={`${config.api.url}/images/${prod.id}/First`}
                                        alt={`Random image`}
                                    />
                                </Link>
                                <Card.Body className="d-flex flex-column justify-content-between" style={{padding:'15px 0 0 0'}}>
                                    <Card.Text>
                                        <Link to={`/productos/${prod.category.id}`}>{prod.category.name}</Link>
                                        <Link to={`/productos/${prod.category.id}/${prod.id}`}>
                                            <span style={{fontSize:'1rem', fontWeight:'600'}} className='d-block'>{prod.name}</span>
                                        </Link>
                                    </Card.Text>
                                    <Card.Title className='font-weight-bold'>{prod.price > 0 ? formatPeso.format(prod.price) : 'GRATIS'}</Card.Title>
                                    <Button variant='purple' size='sm' onClick={handleAddToCart.bind(this, prod)} disabled={prod.stock <= 0}>Agregar al carro</Button>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </CardDeck>
            <Row className='subscribeHome p-3 mt-3 d-none d-md-flex'>
                <Col>
                    <span style={{lineHeight:'30px', display:'inline-block'}}>Suscríbete y tendrás siempre nuestras ofertas y novedades en tu correo electrónico</span>
                </Col>
                <Col md={2} className='text-right'>
                    <Link to='/contactanos' className='btn btn-purple btn-sm'>Suscribirse</Link>
                </Col>
            </Row>
            {
                loadingMain && <div className='text-center'><Spinner animation="grow"/></div>
            }
            <CardDeck className="d-flex justify-content-center justify-content-md-between" style={{'flexWrap':'wrap'}}>
                {
                    !loadingMain && products.length > 0 && products.map((prod) => {
                        return (
                            <Card key={prod.id} className='p-3 shadow text-center mt-3 flex-grow-1 flex-grow-md-0 card-custom mr-0 ml-0'>
                                <Link to={`/productos/${prod.category.id}/${prod.id}`}>
                                    <LazyImage
                                        key={prod.id}
                                        src={`${config.api.url}/images/${prod.id}/First`}
                                        alt={`Random image`}
                                        style={{'height':'100%'}}
                                    />
                                </Link>
                                <Card.Body className="d-flex flex-column justify-content-between">
                                    <Card.Text>
                                        <Link to={`/productos/${prod.category.id}`}>{prod.category.name}</Link>
                                        <Link to={`/productos/${prod.category.id}/${prod.id}`}>
                                            <span style={{fontSize:'1rem', fontWeight:'600'}} className='d-block'>{prod.name}</span>
                                        </Link>
                                    </Card.Text>
                                    <div>
                                        <Card.Title className='font-weight-bold'>{prod.price > 0 ? formatPeso.format(prod.price) : 'GRATIS'}</Card.Title>
                                        <Button variant='purple' size='sm' onClick={handleAddToCart.bind(this, prod)} disabled={prod.stock <= 0}>Agregar al carro</Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        )
                    })
                }
            </CardDeck>
        </Layout>
    )
}
export default Home
