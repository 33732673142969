
import React, { useContext, useEffect, useState } from 'react'
import { Button, Card, Modal } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import { AppContext } from '../../app.context'
import LazyImage from '../shared/lazyimage'
const formatPeso = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
    minimumFractionDigits: 0
})
const AddToCart = (props) => {
    let prod = props.product

    const [config, setConfig] = useContext(AppContext)
    const [exists, setExists] = useState(true)
    useEffect(() => {
        if(prod && prod.id && props.show){
            setExists(true)
            let exists = config.cart.find(x => x.product.id === prod.id) || null
            let temp = [...config.cart]
            if(exists == null) {
                setExists(false)
                temp = [...config.cart, {product:prod, quantity:1}]
            } 
            localStorage.setItem('cart', JSON.stringify(temp))
            setConfig({...config, cart:temp})
        }
    },[prod, props.show])
    const history = useHistory()
    return (
        <Modal
            {...props}
            size='md'
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='text-success h6'>
                    {/* <FontAwesomeIcon icon={faCheckCircle} /> El producto se agregó al carro exitosamente. */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    prod && prod.id && <Card className='flex-row p-3 border-0 shadow'>
                                <Link to={`/productos/${prod.category.id}/${prod.id}`}>
                                    <LazyImage
                                        align='left'
                                        src={`${config.api.url}/images/${prod.id}/First`}
                                        alt={`Random image`}
                                    />
                                </Link>
                                <Card.Body>
                                    <Card.Text>
                                        <Link to={`/productos/${prod.category.id}`}>{prod.category.name}</Link>
                                        <Link to={`/productos/${prod.category.id}/${prod.id}`}>
                                            <span style={{ fontSize: '1rem', fontWeight: '600' }} className='d-block'>{prod.name}</span>
                                        </Link>
                                    </Card.Text>
                                    {
                                        !exists && <div>
                                            <Card.Text className='mb-0'>Cantidad: 1</Card.Text>
                                            <Card.Title className='font-weight-bold'>{prod.price > 0 ? formatPeso.format(prod.price) : 'GRATIS'}</Card.Title>
                                        </div>
                                    }
                                    {
                                        exists && <Card.Text className='mb-0' style={{fontSize:'.8em'}}>El producto seleccionado ya está en su carro de compras </Card.Text>

                                    }
                                </Card.Body>
                                {/* <div className='mr-4 mt-4 text-success' style={{fontSize:'4em'}}><FontAwesomeIcon icon={faCheckCircle} /></div> */}
                            </Card>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => history.push('/carro-de-compra')} variant='purple'>Ir al carro</Button>
                <Button onClick={props.onHide} variant='secondary'>Seguir comprando</Button>
            </Modal.Footer>
        </Modal>
    )
}
export default AddToCart