import { faProductHunt } from '@fortawesome/free-brands-svg-icons'
import { faHome, faTags } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Breadcrumb, Dropdown, Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { Link, useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { getSession } from './auth.service'

const { primaryColor, secondaryColor } = {
    primaryColor: '#ddd',
    secondaryColor: '#eee'
}
const Wrapper = styled.div`
    background-image:linear-gradient(180deg, ${primaryColor}, ${secondaryColor});
    min-height:100vh;
    display:flex;
    font-size:12px;
`
const Navigation = styled.div`
    flex-grow:0;
    width:70px;
    /* padding:30px 10px 30px 10px; */
    text-align:center;
    background-color:white;
`
const Content = styled.div`
    flex-grow:4;
`
const LinkMenu = styled(Link)`
    padding:16px 26px;
    font-size:1.2em;
    display:block;
    :hover, &.active{
        background-color:#ddd;
    }
`
const LinkSubmenu = styled(LinkMenu)`
    font-size:.8em;
`
const ProfileButton = styled(LinkMenu)`
    :hover{
        background-color:#ddd;
    }
`
const Logo = styled(Image)`
    padding:20px 15px;
`
const Top = styled.div`
    background-color:white;
    
`
const Body = styled.div`
    padding:20px 15px;
`
const IconFirstString = styled.span`
    padding:5px 10px;
    color:white;
    background-color:purple;
`
const PageTitle = styled.h1`
    font-weight:300;
    font-size:1.6em;
`
const Layout = (param) => {
    console.log(param)
    const history = useHistory()
    const handleLogout = () => {
        localStorage.removeItem('sess_ba');
        history.push('/login')
    }
    const user = getSession()
    return (
        <Wrapper>
            <Navigation>
                <Logo src='/logo_v1.png' className='w-100' />
                <ul className='list-unstyled mt-4'>
                    <li>
                        <OverlayTrigger placement='right' overlay={(<Tooltip id={`tooltip-right`}>Dashboard</Tooltip>)}>
                            <LinkMenu to='/admin/dashboard' className={param.pageUrl === 'dashboard' ? 'active' : ''}><FontAwesomeIcon icon={faHome} /></LinkMenu>
                        </OverlayTrigger>
                        <OverlayTrigger placement='right' overlay={(<Tooltip id={`tooltip-right`}>Categorías</Tooltip>)}>
                            <LinkMenu to='/admin/categories' className={param.pageUrl === 'categories' ? 'active' : ''}><FontAwesomeIcon icon={faTags} /></LinkMenu>
                        </OverlayTrigger>
                        <OverlayTrigger placement='right' overlay={(<Tooltip id={`tooltip-right`}>Productos</Tooltip>)}>
                            <LinkMenu to='/admin/products' className={param.pageUrl === 'products' ? 'active' : ''}><FontAwesomeIcon icon={faProductHunt} /></LinkMenu>
                        </OverlayTrigger>
                        <OverlayTrigger placement='right' overlay={(<Tooltip id={`tooltip-right`}>Transacciones</Tooltip>)}>
                            <LinkMenu to='/admin/transactions' className={param.pageUrl === 'transactions' ? 'active' : ''}><FontAwesomeIcon icon={faTags} /></LinkMenu>
                        </OverlayTrigger>

                    </li>
                </ul>
            </Navigation>
            <Content className='shadow'>
                <Top className='shadow text-right'>
                    <Dropdown>
                        <Dropdown.Toggle as={ProfileButton} className='d-inline-block'>Hola, {user.name}<IconFirstString className='ml-2'>{user.name.charAt(0)}</IconFirstString></Dropdown.Toggle>
                        <Dropdown.Menu className='p-0'>
                            <Dropdown.Item as={LinkSubmenu} onClick={handleLogout}>Cerrar sesión</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Top>
                <Body>
                    <PageTitle>{param.pageTitle}</PageTitle>
                    {param.children}
                </Body>
            </Content>
        </Wrapper>
    )
}
export default Layout
