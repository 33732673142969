
import React, { useContext } from 'react'
import { Modal, Spinner } from 'react-bootstrap'
import Rug, { DropArea } from 'react-upload-gallery'
import 'react-upload-gallery/dist/style.css'
import toastr from 'toastr'
import { AppContext } from '../../app.context.js'
import { useState } from 'react'
import { useEffect } from 'react'

const ProductsImageForm = (props) => {
    const [config] = useContext(AppContext)
    const [pictures, setPictures] = useState(null)
    useEffect(() => {
        if (props.active.id != null) {
            fetch(`${config.api.url}/images/${props.active.id}`)
                .then(r => r.json())
                .then(data => {
                    data = data.map(i => {
                        i.source = i.image
                        return i
                    })
                    setPictures(data)
                })
        }
    }, [props.active.id])
    return (
        <Modal
            {...props}
            size='lg'
            aria-labelledby="contained-modal-title-vcenter"
            centered
            onShow={() => setPictures(null)}
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter" className='h6' dangerouslySetInnerHTML={{ __html: props.title }}>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    pictures == null && <div className='text-center mt-5 mb-5'><Spinner animation="grow"/></div>
                }
                {
                    props.active.id!=null && pictures!=null && <Rug
                    header={({ openDialogue }) => (
                        <DropArea>
                            {
                                (isDrag) =>
                                    <div style={{ background: isDrag ? '#eee' : '#fff' }} class='border text-center'>
                                        <div class='mt-5 mb-2'>Arrastra la imagen a esta zona</div>
                                        <button onClick={openDialogue} class='btn btn-sm btn-primary mb-5'>Subir imagen</button>
                                    </div>
                            }
                        </DropArea>
                    )}
                    action={`${config.api.url}/images/${props.active.id}`} // upload route
                    source={response => response.source} // response image source
                    initialState={pictures}

                    rules={{
                        limit: 3,
                        size: 800,
                        width: { min: 500, max: 2100 },
                        height: { min: 400, max: 2000 }
                    }}
                    accept={['jpg', 'jpeg', 'png']}
                    onWarning={(type, rules) => {
                        switch (type) {
                            case 'accept':
                                toastr.error(`Solo se aceptan los tipos de archivo ${rules.accept.join(', ')}`)
                                break
                            case 'limit':
                                toastr.error(`Máximo ${rules.limit} imágenes`)
                                break
                            case 'size':
                                toastr.error('El tamaño del archivo debe ser menor o igual a ', rules.size)
                                break
                            case 'minWidth': case 'minHeight':
                                toastr.error(`Las dimensiones de la imagen deben ser mayores a ${rules.width.min}x${rules.height.min}px`)
                                break
                            case 'maxWidth': case 'maxHeight':
                                toastr.error(`Las dimensiones de la imagen deben ser menores a ${rules.width.min}x${rules.height.min}px`)
                                break
                            default:
                        }
                    }}
                    onConfirmDelete={() => { 
                        return window.confirm('¿Está seguro de eliminar esta imagen?')
                    }}
                    onDeleted={(image) => {
                        fetch(`${config.api.url}/images/${image._id}`,{
                            method:'DELETE'
                        })
                        .then(r => r.json())
                        .then(data => console.log(data))
                    }}
                />
                }

                
            </Modal.Body>
        </Modal>

    )
}

export default ProductsImageForm