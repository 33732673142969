import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import NoMatch from "./404";
import Categories from "./admin/categories/categories";
import Dashboard from "./admin/dashboard/dashboard";
import Login from "./admin/login/login";
import ProductsAdmin from "./admin/products/products";
import AboutUs from "./store/pages/aboutus";
import Cart from "./store/pages/cart";
import ContactUs from "./store/pages/contactus";
import Home from "./store/pages/home";
import Product from "./store/pages/product";
import Products from "./store/pages/products";
import Toc from "./store/pages/toc";
import TransferenciaFinish from "./store/pages/transferencia-finish";
import WebpayFinish from "./store/pages/webpay-finish";
import Transactions from "./admin/transactions/transactions";
import RetiroFinish from "./store/pages/retiro-finish";

const App = () => {
    return (
        <BrowserRouter>
            <Switch>
                <Route path='/' exact component = {Home} />
                <Route path='/acerca-de-nosotros' exact component = {AboutUs} />
                <Route path='/terminos-y-condiciones' exact component = {Toc} />
                <Route path='/contactanos' exact component = {ContactUs} />
                <Route path='/productos/:category/:id' exact component={Product} />
                <Route path='/productos/:category' exact component={Products} />
                <Route path='/productos/' exact component={Products} />
                <Route path='/carro-de-compra/' exact component={Cart} />
                <Route path='/compra-exitosa-webpay/' exact component={WebpayFinish} />
                <Route path='/compra-exitosa-transferencia/' exact component={TransferenciaFinish} />
                <Route path='/compra-exitosa-retiro/' exact component={RetiroFinish} />
                
                <Route path='/login/' exact component={Login} />
                <Route path='/admin/dashboard/' exact component={Dashboard} />
                <Route path='/admin/categories/' exact component={Categories} />
                <Route path='/admin/products/' exact component={ProductsAdmin} />
                <Route path='/admin/transactions/' exact component={Transactions} />

                <Route path='*' component={NoMatch} />
            </Switch>
        </BrowserRouter>
    )
}

export default App
